  <template>
  <div id="about-textree">
    <div class="about-head animate__animated animate__fadeInLeft">
      <div class="about-title">Our Values</div>
    </div>
    <div class="about-content">
      <div class="content">
        <div>
          <hr>
          <div class="content-title animate__animated animate__fadeInLeft">고객이 필요한 정보를 다양한 매체를 통하여 쉽고 정확히 접근하여 제품과 기업에 대한 로열티를 제고합니다.</div>
          <div class="content-desc  animate__animated animate__fadeInLeft">
            <ul>
              <li>웹/모바일, 다양한 디바이스를 지원하는 고객용 기술 문서 포털 구축</li>
              <li>다양한 탐색, 검색 방법 및 질의 응답, 피드백 등의 개인화 기능 제공</li>
              <li>PDF, Web Help, AR/VR 등의 다채널 멀티미디어 컨텐츠 지원</li>
              <li>온/오프라인 배포 환경 지원</li>
            </ul>
          </div>
        </div>
        <div>
          <div class="content-title  animate__animated animate__fadeInLeft">제작자 및 관리자를 위한 비용 및 생산성을 향상과, 지속적인 개선을 통해 도큐멘테이션 가치를 강화하는 방법을 지원합니다.</div>
          <div class="content-desc  animate__animated animate__fadeInLeft">
            <ul>
              <li>모듈 문서 재사용을 통한 다중 문서(설계, 사양, 설치, 매뉴얼 등) 일관성 확보, 시간 및 비용 절감</li>
              <li>컨텐츠 중앙 관리를 통한 버전 이력 관리 및 검수 협업 환경 개선</li>
              <li>Pdf, webhelp 자동 출판을 통한 생산 시간 및 비용 절감</li>
              <li>배포 시스템 연계를 통한 컨텐츠 업데이트 및 신속 배포 프로세스 개선</li>
              <li>고객의 컨텐츠 소비 방식 분석, 이를 통한 문서 개선 의사 결정 지원, 지속적인 가치 강화</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cover-container animate__animated animate__fadeInLeft">
        <div class="cover animate__animated animate__fadeInLeft animate__delay-1s" style="background-image: url('/assets/images/about/1695247384.png')"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
div#about-textree {
  height: 100%;
  max-width: calc(1600px - 15%);
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
}
div.about-title {
  font-family: "Mpntserrat";
  font-size: 60px;
  font-weight: bold;
  color: #008ED6;
}

hr {
  max-width: 100px;
  height: 5px;
  background: #F1B500;
  border: none;
  margin: 48px 0;
}

div.about-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
div.cover-container {
  background-image: url('/assets/images/pattern-yellow.png');
  background-position: right top;
  background-repeat: no-repeat;
  flex: 0 0 50%;
  height: 100%;
  position: relative;
}
div.cover {
  position: absolute;
  top: 24px;
  right: 48px;
  width: 180%;
  height: calc(100% - 24px);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
}

div.content {
  display: flex;
  justify-content: space-between;
}
div.content > div {
  flex: 0 0 calc(50% - 24px);
}
div.content-title {
  font-family: "Nanum Gothic";
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
  color: #008ED6;
}
div.content-desc {
  font-family: "Nanum Gothic";
  font-size: 18px;
  color: #707070;
  line-height: 40px;
  margin: 24px 0;
}
div.content-desc ul {
  padding-left: 20px;
}

@media(max-width: 1440px) {
  div#about-textree {
    width: calc(100% - 48px);
    padding: 0 24px;
    max-width: 100%;
  }
  div.content-title {
    line-height: 1.5;
  }
  div.content-desc {
    line-height: 2;
  }
}
@media(max-width: 1024px) {
  div.about-title {
    font-size: 48px;
  }
  div.content-title {
    line-height: 1.5;
  }
  div.content-desc {
    line-height: 1.5;
    font-size: 16px;
  }
  hr {
    margin: 24px 0;
  }
}

@media(max-width: 768px) {
  div.about-title {
    font-size: 35px;
  }
  div.content > div {
    flex-basis: calc(50% - 12px);
  }
  div.content-title {
    font-size: 18px;
  }
  div.content-desc {
    margin: 0;
    font-size: 12px;
  }
  div.content-desc li {
    margin-bottom: 6px;
  }
}

@media(max-width: 576px) {
  div.content-title {
    font-size: 16px;
    line-height: 1.3;
  }
  div.content-desc {
    margin: 0;
    font-size: 12px;
    line-height: 1.3;
  }
  div.content-desc li {
    margin-bottom: 3px;
  }
  div.cover {
    top: 0;
    right: -24px;
    width: calc(100% + 48px);
  }
}

@media(max-height: 680px) {
  div.about-title {
    font-size: 24px;
  }
  hr {
    margin: 12px 0;
  }
  div.content-title {
    font-size: 12px;
  }
}
</style>
